<template lang="pug">
.table-icons
  FontAwesomeIcon.icon(
    v-for="index in cellNumber"
    :key="index"
    :icon="doTakeIcon(index)"
    @click="$emit('emit', {index, rowNumber})"
  )
</template>

<script>
// import {
//   mapGetters,
//   mapActions
// } from 'vuex'

export default {
  name: 'TableIcons',
  props: {
    cellNumber: {
      type: Number,
      required: true,
      default: () => 0
    },
    rowNumber: {
      type: Number,
      required: true,
      default: () => 0
    }
  },
  methods: {
    doTakeIcon (index, cellNumber) {
      if (cellNumber === 1) {
        return ['fas', 'lock']
      } else {
        if (index === 1) {
          return ['fas', 'edit']
        } else if (index === 2) {
          return ['fas', 'chevron-down']
        } else {
          return ['fas', 'chevron-up']
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
  .table-icons
    display: flex
    flex-direction: row
    .icon
      margin: 8px

</style>
